import React from 'react';
import { useApi } from '@api';
import Loader from '@components/Loader/';
import ApiRequestPayload from '@customTypes/ApiRequestPayload';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { EventDataBuilder, EventType, sendAnalyticsEvent } from '@lib-components/Analytics';

type FormConnectorProps = {
  formConnector: {
    api: string;
    errorLabel?: string;
  };
  onSubmit?: (payload: ApiRequestPayload) => any;
};

function FormConnector(WrappedComponent: React.ComponentType<FormConnectorProps>) {
  return function FormConnectorWrapper(props: FormConnectorProps) {
    const { formConnector } = props;

    const api = useApi();
    const queryClient = useQueryClient();

    const services: Record<string, (...args: any) => any> = {
      'POST /contacts/': api.addContact.bind(api),
      'POST /drivers/': api.addDriver.bind(api),
      'PUT /contacts/': api.changeContact.bind(api),
      'PUT /drivers/': api.changeDriver.bind(api),
      'PUT /account/': api.changeAccount.bind(api),
      'PUT /paymentMethod/': api.changePaymentMethod.bind(api),
    };

    const { api: apiPath } = formConnector || {};
    const modelKey = formConnector?.api?.split('/')[1];

    const { mutate, isLoading, isError, error } = useMutation<any, unknown, ApiRequestPayload, unknown>({
      mutationFn: async (data: ApiRequestPayload) => {
        if (!services[apiPath]) {
          throw new Error(`${apiPath} don't exist in FormConnector`);
        }

        return services[apiPath](data);
      },
    });

    if (!formConnector) {
      return <WrappedComponent {...props} />;
    }

    const onSubmit = (submittedPayload: ApiRequestPayload) => {
      return mutate(submittedPayload, {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: [modelKey] });
          sendAnalyticsEvent(new EventDataBuilder(EventType.FormSucceededEvent).withArgs({ name: modelKey }));
        },
        onError: (e: any) => {
          console.error(e);
          sendAnalyticsEvent(
            new EventDataBuilder(EventType.FormFailedEvent).withArgs({ name: modelKey, error: (e as Error).message }),
          );
        },
      });
    };

    if (isLoading) {
      return <Loader />;
    }

    if (isError) {
      console.error('Form Connector error', formConnector, error);
      return <div className="error">{formConnector.errorLabel}</div>;
    }
    return <WrappedComponent onSubmit={onSubmit} {...props} />;
  };
}

export default FormConnector;
